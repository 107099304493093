<template>
<div class="page404">
  <div class="row">
    <div class="col s12 m6">
      <div class="card blue-grey darken-1">
        <div class="card-content white-text">
          <span class="card-title">Это не та страница, на которую не следует заходить</span>
          <p>Попробуйте правильно написать адрес страницы</p>
        </div>
        <div class="card-action">
          <a @click="$router.go(-1)">Назад</a>
          <router-link :to="{name:'mainpage'}">Главная</router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped>
a{
  cursor:pointer;
}
</style>