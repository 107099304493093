export default {
    namespaced: true,
    state: {
        apiroot: '/',
        // categoryValues:['осн','л'],
        categoryValues: window.globalCategoryValues,
    },
    actions: {
        log(ctx, str = '') {
            console.log(str);
        },
        getCategoriesFromWindow(ctx) {
            console.log('!!!!!', window.globalCategoryValues);
            //ctx.commit('mutGetCategoriesFromWindow', window.globalCategoryValues);
        },
    },
    mutations: {
        mutGetCategoriesFromWindow(state, c) {
            state.categoryValues = c;
        },
    },
    getters: {
        getApiRoot(state) {
            return state.apiroot;
        },
        categoryValues(state) {
            return state.categoryValues;
        },
    },
}