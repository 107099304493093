import Vue from 'vue'
import Vuex from 'vuex'
import Config from './modules/config'
import User from './modules/user'

Vue.use(Vuex);
export default new Vuex.Store({
    modules:{
        User, Config,
    }
})