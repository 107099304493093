<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters('User', {me: 'getUser',isAdmin:'isAdmin',isLoggedIn:'isLoggedIn'}),
    ...mapGetters({getApiRoot: 'Config/getApiRoot',}),
    ...mapActions({}),
  },
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
