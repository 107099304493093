import axios from "axios";

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        isLoggedInCheck: false,
        user: {
            f: '',
            i: '',
            o: '',
            fullname: '',
            userid: 0,
            permissions: 'guest',
        },
        refreshDate: 0,
        refreshing: false,
    },
    actions: {
        async reverseLoggedState(ctx) {
            ctx.commit('reverseLoggedState');
        },
        async logInActions(ctx,res){
            console.log('in login actions');
            async function startLogInActions(res){
                console.log('login actions..');
                if (res.status == 200 && res.data && (Array.isArray(res.data) || typeof res.data === 'object' ) && 'login' in res.data && res.data.login) {
                    await axios.get(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'orders/haveDirectorPermissions').then((hdpres) => {
                        if (hdpres.status == 200 && hdpres.data && 'message' in hdpres.data && hdpres.data.message==="OK") {
                            res.data.permissions='director';
                        }
                    }, () => {
                    });
                    ctx.commit('doLogIn', res.data);
                }
            }
            if (res.status == 200 && res.data==="OK") {
                await axios.get(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'login/').then(startLogInActions, () => {
                });
            }
            if (res.status == 200 && res.data && (Array.isArray(res.data) || typeof res.data === 'object' ) && 'login' in res.data && res.data.login) {
                await startLogInActions(res);
            }
        },
        async userLogIn(ctx, cred) {
            await axios.post(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'login/', cred).then(async (res) => {
                await ctx.dispatch('logInActions',res);
            }, () => {
            });
        },
        async userLogOut(ctx) {
            await axios.delete(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'login/').then((res) => {
                if (res.status == 200) {
                    ctx.commit('doLogOut');
                }
            }, () => {
            });
        },
        async sessionRefresh(ctx) {
            console.log('=>sess refresh');
            await ctx.dispatch('isLoggedInCheck');
            if (ctx.rootGetters["User/isLoggedInCheck"]){
                console.log('user is already logged in!');
                await axios.get(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'login/').then(async (res) => {
                    await ctx.dispatch('logInActions',res);
                }, () => {
                });
            }

            // let now = Date.now();
            // if (((now - ctx.state.refreshDate) / 1000 > 5) && !ctx.state.refreshing) {
            //     ctx.commit('setRefreshing', true);
            //     let res={data:"OK",status:200};
//                await ctx.dispatch('logInActions',res);
                // await axios.get(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'login/').then(async (res) => {
                //     await ctx.dispatch('logInActions',res);
                // }, () => {
                // });
//                ctx.commit('setRefreshing', false);
//            }
            console.log('sess refreshed!');
        },
        async isLoggedInCheck(ctx) {
                await axios.get(process.env.VUE_APP_LINK + ctx.rootGetters["Config/getApiRoot"] + 'orders/isloggedin').then(async (res) => {
                    if (res.status != 200)
                        ctx.commit('setIsLoggedInCheck', false);
                    else ctx.commit('setIsLoggedInCheck', true);
                }, () => {
                    ctx.commit('setIsLoggedInCheck', false);
                });
        },
    },
    mutations: {
        reverseLoggedState(state) {
            state.isLoggedIn = !state.isLoggedIn;
        },
        setIsLoggedInCheck(state,data){
            state.isLoggedInCheck=data;
        },
        doLogIn(state, data) {
            console.log('setting user to be logged in');
            state.user.f = data.f;
            state.user.i = data.i;
            state.user.o = data.o;
            state.user.fullname = data.i + ' ' + data.o + ' ' + data.f;
            state.user.userid = Number(data.userid);
            state.user.permissions = data.permissions?data.permissions:'guest';

            state.isLoggedIn = true;
            state.refreshDate = Date.now();
        },
        doLogOut(state) {
            state.user.f = '';
            state.user.i = '';
            state.user.o = '';
            state.user.fullname = '';
            state.user.userid = 0;
            state.isLoggedIn = false;
            state.refreshDate = null;
            state.user.permissions = 'guest';
            console.log('logouted');
        },
        setRefreshing(state, data) {
            state.refreshing = data;
        },
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        isLoggedInCheck(state) {
            return state.isLoggedInCheck;
        },
        isDirector(state) {
            return state.user.permissions == 'director';
        },
        getUserName(state) {
            return state.user.i;
        },
        getUserSecondName(state) {
            return state.user.o;
        },
        getUserID(state) {
            return state.user.userid;
        },
        getUser(state) {
            return state.user;
        },

    },
}
