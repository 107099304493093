import VueRouter from 'vue-router'
// eslint-disable-next-line no-unused-vars
import store from "@/store/store";
import Site from "@/components/Site";
import Page404 from "@/components/Page404";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/", name: "mainpage", meta: {public: true,}, component: Site, children: [
                {
                    path: 'login',
                    name: 'login',
                    meta: {public: true,},
                    component: () => import("@/components/LoginForm")
                },
                {
                    path: 'orders',
                    name: 'orders',
                    meta: {public: false,},
                    component: () => import("@/components/Adminka"),
                    children: [
                        {
                            path: 'list',
                            name: 'orderslist',
                            meta: {public: false,},
                            component: () => import("@/components/OrdersList")
                        },
                        {
                            path: 'add',
                            name: 'addorder',
                            meta: {public: false,},
                            component: () => import("@/components/AddPrikazForm")
                        },
                        {
                            path: ":orderid(\\d+)",
                            name: "editorder",
                            meta: {public: false,},
                            component: () => import("@/components/EditForm"),
                        },
                    ],
                },
                // {
                //     path: "/u/:userid(\\d+)",
                //     name: "profile",
                //     meta: {public: true,},
                //     component: () => import("/components/profile/Profile"),
                // },
            ]
        },
        {name: "page404", meta: {public: true,}, component: Page404, path: "*"},
    ],
    linkActiveClass: 'active',
});
router.beforeEach(async (to, from, next) => {
    let mypath = to.path;
    console.log('going to ' + mypath);
    if (mypath == '' || mypath == '/') {
        // if (!store.getters['User/isLoggedIn']) {
        //     await store.dispatch('User/sessionRefresh');
        // }
        if (!store.getters['User/isLoggedIn']) {
            console.log('next to login');
            next({
                name: 'login',
                //query: {redirect: to.fullPath}
            })
        } else {
            next({name: 'orderslist'});
        }
    }
    if (mypath[mypath.length - 1] == '/') mypath = mypath.substr(0, mypath.length - 1);
    let last = mypath.split('/').pop();
    console.log('last=' + last,store.getters['User/isLoggedIn']);
    switch (last) {
        case 'orders':
            console.log('redirected from ' + mypath);
            next({name: 'orderslist',});
            break;
        // case 'login':
        //     next();
        //     break;
        // case 'news': // redirect to main page
        //     next({name: 'mainpage',});
        //     break;
        // case 'main':
        //     next({name: 'forumindex',});
        //     break;
        default: {
            if (to.matched.some((e) => (!e.meta["public"]))) {
                // этот путь требует авторизации, проверяем залогинен ли
                // пользователь, и если нет, перенаправляем на страницу логина
                if (!store.getters['User/isLoggedIn']) {
                    //await store.dispatch('User/sessionRefresh');
                    next({
                        name: 'login',
                        query: {redirect: to.fullPath}
                    })
                }
                if (store.getters['User/isLoggedIn']) {
                    await store.dispatch('User/isLoggedInCheck');
                    if (!store.getters['User/isLoggedInCheck']) {
                        next({
                            name: 'login',
                            query: {redirect: to.fullPath}
                        })
                    } else next();
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
});

export {router}
