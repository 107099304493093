import VueRouter from "vue-router";
import Vue from 'vue'
import App from './App.vue'
import VueAxios from "vue-axios";
import axios from "axios";
import store from './store/store';
import { router } from './routes.js';
import 'materialize-css';
require("materialize-css/dist/js/materialize")

require('normalize.css');
require('./assets/app.scss');
require('materialize-css/dist/css/materialize.css');
require('material-design-icons/iconfont/material-icons.css');
// import 'vue-select/dist/vue-select.css';
axios.defaults.withCredentials = true;
Vue.prototype.$window = window
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueAxios, axios);


new Vue({
  el: "#app",
  store,
  render: h => h(App),
  router,
});
