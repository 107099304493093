<template>
  <div class="site container">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Site",
  async mounted() {
    // this.getCategoriesFromWindow();
  },
  methods: {
    ...mapActions('Config', ['getCategoriesFromWindow']),
  },
}
</script>

<style scoped lang="scss">
.site {
  margin-bottom: 50px;
}
</style>